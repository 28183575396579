*::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

*::-webkit-scrollbar:horizontal {
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #ffffff20;
  min-height: 30px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #6b76a180;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

body {
  height: 100% !important;
  font-family: unset !important;
  padding: unset !important;
  min-width: unset !important;
  font-size: unset !important;
}

fieldset {
  padding: 0.35rem 0.75rem 0.625rem !important;
}

a {
  text-decoration: none;
  color: unset;
}

.COMMON {
  box-shadow: inset -5px 2px 32px -9px #fff;
}

.GEN1 {
  box-shadow: inset -5px 2px 32px -9px #c5c5c5;
}
.RARE {
  box-shadow: inset -5px 2px 32px -9px #06bcff;
}

.EPIC {
  box-shadow: inset -5px 2px 32px -9px #c49fff;
}

.LEGENDARY {
  box-shadow: inset -5px 2px 32px -9px #ffed2c;
}
